<template>
    <div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0 text-dark">All Area</h1>
          </div><!-- /.col -->
          <div class="col-sm-6">
            <button class="btn btn-info float-right" @click="showCreateModal"><i class="fas fa-plus-circle"></i> Create</button>
            <!-- <button class="btn btn-success float-right mr-2" @click="showBulkModal"><i class="fas fa-upload"></i> Bulk Upload</button> -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->
    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-md-1">
                                <div class="form-group">
                                    <label for="id">ID</label>
                                    <input type="text" id="id" v-model="search.id" placeholder="Enter ID" class="form-control" />
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group">
                                    <label for="coverage_area_id">Coverage Area</label>
                                    <v-select name="coverage_area_id"
                                        v-model="search.coverage_area_id"
                                        label="text"
                                        :reduce="item => item.id"
                                        :options= commonObj.coverageAreaList
                                        :placeholder="$t('globalTrans.select')"
                                    />
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group">
                                    <label for="division_id">Division</label>
                                    <v-select name="division_id"
                                        v-model="search.division_id"
                                        label="text"
                                        :reduce="item => item.id"
                                        :options= commonObj.divisionList
                                        :placeholder="$t('globalTrans.select')"
                                    />
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group">
                                    <label for="district_id">District</label>
                                    <v-select name="district_id"
                                        v-model="search.district_id"
                                        label="text"
                                        :reduce="item => item.id"
                                        :options= districtList
                                        :placeholder="$t('globalTrans.select')"
                                    />
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group">
                                    <label for="thana_id">Thana</label>
                                    <v-select name="thana_id"
                                        v-model="search.thana_id"
                                        label="text"
                                        :reduce="item => item.id"
                                        :options= thanaList
                                        :placeholder="$t('globalTrans.select')"
                                    />
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group">
                                    <label for="hub_id">Hub</label>
                                    <v-select name="hub_id"
                                        v-model="search.hub_id"
                                        label="text"
                                        :reduce="item => item.id"
                                        :options= commonObj.hubList
                                        :placeholder="$t('globalTrans.select')"
                                    />
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group">
                                    <label for="name">Area Name</label>
                                    <input type="text" id="name" v-model="search.name" placeholder="Area Name" class="form-control" />
                                </div>
                            </div>
                            <div class="col-md-1">
                                <div class="form-group">
                                    <button class="btn btn-info btn-sm mr-2" type="submit" @click.prevent="searchData" style="margin-top:28px;"><i class="fa fa-search"></i></button>
                                    <a style="margin-top:30px;" class="btn btn-warning btn-sm" target="_blank" :href="$baseUrl + 'excel-download/area?coverage_area_id='+ search.coverage_area_id + '&id='+ search.id + '&division_id='+ search.division_id 
                                        + '&district_id='+ search.district_id + '&thana_id='+ search.thana_id + '&hub_id='+ search.hub_id">
                                        <i class="fa fa-download"></i> Excel
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <b-overlay :show="loader">
                            <div class="table-responsive">
                                <table class="table table-bordered table-striped table-sm">
                                    <thead>
                                        <tr class="text-center">
                                            <th>Total Area</th>
                                            <th>Hub Assigned Area</th>
                                            <th>Hub Unassigned Area</th>
                                            <!-- <th>Zone Unassigned Area</th> -->
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="text-center text-bold" v-if="summary">
                                            <td>{{ summary.total_area }}</td>
                                            <td>{{ summary.hub_area }}</td>
                                            <td>{{ summary.total_area - summary.hub_area }}</td>
                                            <!-- <td>00</td> -->
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </b-overlay>
                    </div>
                    <div class="card-body">
                        <b-overlay :show="loader">
                            <div class="table-responsive">
                                <table class="table table-bordered table-striped table-sm">
                                    <thead>
                                        <tr>
                                            <th>Area ID</th>
                                            <th>Name</th>
                                            <th>Hub Name</th>
                                            <th>Coverage Area</th>
                                            <th>Division</th>
                                            <th>District</th>
                                            <th>Thana</th>
                                            <th>Created By</th>
                                            <th>Updated By</th>
                                            <th style="text-align:center;">Action</th>
                                        </tr>
                                    </thead>
                                    <tfoot>
                                        <tr>
                                            <th>Area ID</th>
                                            <th>Name</th>
                                            <th>Hub Name</th>
                                            <th>Coverage Area</th>
                                            <th>Division</th>
                                            <th>District</th>
                                            <th>Thana</th>
                                            <th>Created By</th>
                                            <th>Updated By</th>
                                            <th style="text-align:center;">Action</th>
                                        </tr>
                                    </tfoot>
                                    <tbody>
                                        <tr v-for="(item, index) in listData" :key="index">
                                            <td>A - {{ item.id }}</td>
                                            <td>{{ item.name }}</td>
                                            <td>{{ item.hub ? item.hub.name : '' }}</td>
                                            <td>{{ item.coverage_area ? item.coverage_area.name : '' }}</td>
                                            <td>{{ item.division ? item.division.name : '' }}</td>
                                            <td>{{ item.district ? item.district.name : '' }}</td>
                                            <td>{{ item.thana ? item.thana.name : '' }}</td>
                                            <td>{{ item.createdby ? item.createdby.name : '' }}</td>
                                            <td>{{ item.updatedby ? item.updatedby.name : '' }}</td>
                                            <td style="text-align:center;">
                                                <button class="btn btn-warning btn-sm mr-1" @click="editArea(item)"><i class="fas fa-pencil-alt"></i></button>
                                                <!-- <button class="btn btn-danger btn-sm" @click="deleteArea(key, item.id)"><i class="fas fa-trash"></i></button> -->
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="mt-3">
                                <b-pagination
                                    v-model="pagination.currentPage"
                                    :per-page="pagination.perPage"
                                    :total-rows="pagination.totalRows"
                                    @input="searchData"
                                />
                            </div>
                        </b-overlay>
                    </div>
                </div>
            </div>
        </div>
      </div><!-- /.container-fluid -->
    </section>
    <div v-if="createModal" class="modal fade show" style="display:block" tabindex="-1" id="addHubModal" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <b-overlay :show="loader">
                    <div class="modal-header bg-info text-white">
                        <h5 class="modal-title text-center w-100">Create New Area</h5>
                        <button type="button" class="close" aria-label="Close" @click="cancelModal">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                            <form @submit.prevent="handleSubmit(createArea)">
                                <div class="row">
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <ValidationProvider name="Area Name" vid="name" v-slot="{errors}" rules="required">
                                            <div class="form-group">
                                                <label for="name">Area Name <span class="text-danger" title="Required">*</span></label>
                                                <input type="text" id="name" v-model="form.name" class="form-control" placeholder="Enter Area Name">
                                                 <span class="text-danger">{{ errors[0] }}</span>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <ValidationProvider name="Coverage Area" vid="coverage_area_id" v-slot="{errors}" rules="required|min_value:1">
                                            <div class="form-group">
                                                <label for="name">Coverage Area <span class="text-danger" title="Required">*</span></label>
                                                <v-select name="coverage_area_id"
                                                    v-model="form.coverage_area_id"
                                                    label="text"
                                                    :reduce="item => item.id"
                                                    :options= commonObj.coverageAreaList
                                                    :placeholder="$t('globalTrans.select')"
                                                />
                                                <span class="text-danger">{{ errors[0] }}</span>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <ValidationProvider name="Division" vid="division_id" v-slot="{errors}" rules="required|min_value:1">
                                            <div class="form-group">
                                                <label for="name">Division <span class="text-danger" title="Required">*</span></label>
                                                <v-select name="division_id"
                                                    v-model="form.division_id"
                                                    label="text"
                                                    :reduce="item => item.id"
                                                    :options= commonObj.divisionList
                                                    :placeholder="$t('globalTrans.select')"
                                                />
                                                <span class="text-danger">{{ errors[0] }}</span>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <ValidationProvider name="District" vid="district_id" v-slot="{errors}" rules="required|min_value:1">
                                            <div class="form-group">
                                                <label for="name">District <span class="text-danger" title="Required">*</span></label>
                                                <v-select name="district_id"
                                                    v-model="form.district_id"
                                                    label="text"
                                                    :reduce="item => item.id"
                                                    :options= districtList
                                                    :placeholder="$t('globalTrans.select')"
                                                />
                                                <span class="text-danger">{{ errors[0] }}</span>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <ValidationProvider name="Thana" vid="thana_id" v-slot="{errors}" rules="required|min_value:1">
                                            <div class="form-group">
                                                <label for="name">Thana <span class="text-danger" title="Required">*</span></label>
                                                <v-select name="thana_id"
                                                    v-model="form.thana_id"
                                                    label="text"
                                                    :reduce="item => item.id"
                                                    :options= thanaList
                                                    :placeholder="$t('globalTrans.select')"
                                                />
                                                <span class="text-danger">{{ errors[0] }}</span>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <ValidationProvider name="Hub" vid="hub_id" v-slot="{errors}" rules="required|min_value:1">
                                            <div class="form-group">
                                                <label for="name">Hub <span class="text-danger" title="Required">*</span></label>
                                                <v-select name="thana_id"
                                                    v-model="form.hub_id"
                                                    label="text"
                                                    :reduce="item => item.id"
                                                    :options= commonObj.hubList
                                                    :placeholder="$t('globalTrans.select')"
                                                />
                                                <span class="text-danger">{{ errors[0] }}</span>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </form>
                        </ValidationObserver>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-info" @click.prevent="createArea">Save</button>
                        <button type="button" class="btn btn-danger" @click="cancelModal">Cancel</button>
                    </div>
                </b-overlay>
            </div>
        </div>
    </div>
    <div v-if="editModal" class="modal fade show" style="display:block" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <b-overlay :show="loader">
                    <div class="modal-header bg-info text-white">
                        <h5 class="modal-title text-center w-100">Edit Area</h5>
                        <button type="button" class="close" aria-label="Close" @click="cancelModal">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                         <ValidationObserver ref="editForm" v-slot="{ handleSubmit }">
                            <form @submit.prevent="handleSubmit(updateArea)">
                                <!-- <input type="hidden" v-model="form_edit.id" /> -->
                                <div class="row">
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <ValidationProvider name="Area Name" vid="name" v-slot="{errors}" rules="required">
                                            <div class="form-group">
                                                <label for="name">Area Name <span class="text-danger" title="Required">*</span></label>
                                                <input type="text" id="name" v-model="form_edit.name" class="form-control" placeholder="Enter Area Name">
                                                 <span class="text-danger">{{ errors[0] }}</span>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <ValidationProvider name="Coverage Area" vid="coverage_area_id" v-slot="{errors}" rules="required|min_value:1">
                                            <div class="form-group">
                                                <label for="name">Coverage Area <span class="text-danger" title="Required">*</span></label>
                                                <v-select name="coverage_area_id"
                                                    v-model="form_edit.coverage_area_id"
                                                    label="text"
                                                    :reduce="item => item.id"
                                                    :options= commonObj.coverageAreaList
                                                    :placeholder="$t('globalTrans.select')"
                                                />
                                                <span class="text-danger">{{ errors[0] }}</span>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <ValidationProvider name="Division" vid="division_id" v-slot="{errors}" rules="required|min_value:1">
                                            <div class="form-group">
                                                <label for="name">Division <span class="text-danger" title="Required">*</span></label>
                                                <v-select name="division_id"
                                                    v-model="form_edit.division_id"
                                                    label="text"
                                                    :reduce="item => item.id"
                                                    :options= commonObj.divisionList
                                                    :placeholder="$t('globalTrans.select')"
                                                />
                                                <span class="text-danger">{{ errors[0] }}</span>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <ValidationProvider name="District" vid="district_id" v-slot="{errors}" rules="required|min_value:1">
                                            <div class="form-group">
                                                <label for="name">District <span class="text-danger" title="Required">*</span></label>
                                                <v-select name="district_id"
                                                    v-model="form_edit.district_id"
                                                    label="text"
                                                    :reduce="item => item.id"
                                                    :options= districtList
                                                    :placeholder="$t('globalTrans.select')"
                                                />
                                                <span class="text-danger">{{ errors[0] }}</span>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <ValidationProvider name="Thana" vid="thana_id" v-slot="{errors}" rules="required|min_value:1">
                                            <div class="form-group">
                                                <label for="name">Thana <span class="text-danger" title="Required">*</span></label>
                                                <v-select name="thana_id"
                                                    v-model="form_edit.thana_id"
                                                    label="text"
                                                    :reduce="item => item.id"
                                                    :options= thanaList
                                                    :placeholder="$t('globalTrans.select')"
                                                />
                                                <span class="text-danger">{{ errors[0] }}</span>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <ValidationProvider name="Hub" vid="hub_id" v-slot="{errors}" rules="required|min_value:1">
                                            <div class="form-group">
                                                <label for="name">Hub <span class="text-danger" title="Required">*</span></label>
                                                <v-select name="thana_id"
                                                    v-model="form_edit.hub_id"
                                                    label="text"
                                                    :reduce="item => item.id"
                                                    :options= commonObj.hubList
                                                    :placeholder="$t('globalTrans.select')"
                                                />
                                                <span class="text-danger">{{ errors[0] }}</span>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </form>
                        </ValidationObserver>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-info" @click.prevent="updateArea">Update</button>
                        <button type="button" class="btn btn-danger" @click="cancelModal">Cancel</button>
                    </div>
                </b-overlay>
            </div>
        </div>
    </div>
    <div v-if="deleteModal" class="modal fade show" style="display:block" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-default" role="document">
            <div class="modal-content">
                <b-overlay :show="loader">
                    <div class="modal-header bg-info text-white">
                    <h5 class="modal-title text-center w-100">Delete Confirmation</h5>
                    </div>
                    <div class="modal-body">
                        <input type="hidden" name="del_id" />
                        <h3 class="text-center">Are you sure to delete ?</h3>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-info" @click="destroyArea">Delete</button>
                        <button type="button" class="btn btn-danger" @click="cancelModal">Cancel</button>
                    </div>
                </b-overlay>
            </div>
        </div>
    </div>    
    <div v-if="bulkModal" class="modal fade show" style="display:block" tabindex="-1" id="deleteZoneAreaModal" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-default" role="document">
            <div class="modal-content">
                <b-overlay :show="loader">
                    <div class="modal-header bg-info text-white">
                        <h5 class="modal-title text-center w-100">Upload Zone Area</h5>
                        <button type="button" class="close" aria-label="Close" @click="cancelModal">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                            <form @submit.prevent="handleSubmit(bulkUploadSave)">
                                <div class="row">
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <ValidationProvider name="Zone" vid="zone_id" v-slot="{errors}" rules="required|min_value:1">
                                            <div class="form-group">
                                                <label for="zone_area_file">File <span class="text-danger" title="Required">*</span></label>
                                                <input type="file" @change="onFileChange" class="form-control" accept="xlsx/*" />
                                                <span class="text-danger">{{ errors[0] }}</span>
                                            </div>
                                        </ValidationProvider>
                                    </div>              
                                </div>
                            </form>
                        </ValidationObserver>
                    </div>
                    <div class="modal-footer">
                        <a :href="this.$image_path+'uploads/zone_area/bulk_zone_area.xlsx'" class="btn btn-info btn-sm" download>Download Sample Format</a>
                        <button type="button" class="btn btn-info btn-sm" @click.prevent="bulkUploadSave">Upload</button>
                        <button type="button" class="btn btn-danger btn-sm" @click="cancelModal">Cancel</button>
                    </div>
                </b-overlay>
            </div>
        </div>
    </div>
    <!-- /.content -->
  </div>
</template>
<script>
import config from '@/config'
export default {
    name:'Area',
    data(){
        return {
            loader: false,
            summary: null,
            search: {
                coverage_area_id : '',
                division_id : '',
                district_id : '',
                thana_id : '',
                hub_id : '',
                name: '',
                id: ''
            },
            form:{
                coverage_area_id : '',
                division_id : '',
                district_id : '',
                thana_id : '',
                hub_id : '',
                name: '',
            },
            form_edit:'',
            errors:[],
            createModal:false,
            editModal:false,
            deleteModal:false,
            bulkModal:false,
            bulk_upload: {
                excel_file: ''
            },
            area_id:'',
            key:'',
            districtList: [],
            thanaList: [],
            pagination: {
                currentPage: 1,
                totalRows: 0,
                perPage: this.$store.state.commonObj.perPage,
                slOffset: 1
            }
        }
    },
    created(){
        this.loadSummary()
        this.loadData()
    },
    watch: {
        stateReload : function (newVal, oldVal) {
            if (newVal != oldVal) {
                this.loadSummary()
                this.loadData()
            }
        },
        'search.name': function (newVal, oldVal) {
            if(newVal != oldVal) {                   
                this.loadData()
            }
        },
        'search.division_id': function (newVal, oldVal) {
            if (newVal != oldVal) {
                this.districtList = this.getDistrictList(newVal)
            }
        },
        'search.district_id': function (newVal, oldVal) {
            if (newVal != oldVal) {
                this.thanaList = this.getThanaList(newVal)
            }
        },
        'form.division_id': function (newVal, oldVal) {
            if (newVal != oldVal) {
                this.districtList = this.getDistrictList(newVal)
            }
        },
        'form.district_id': function (newVal, oldVal) {
            if (newVal != oldVal) {
                this.thanaList = this.getThanaList(newVal)
            }
        },
        'form_edit.division_id': function (newVal, oldVal) {
            if (newVal != oldVal) {
                this.districtList = this.getDistrictList(newVal)
            }
        },
        'form_edit.district_id': function (newVal, oldVal) {
            if (newVal != oldVal) {
                this.thanaList = this.getThanaList(newVal)
            }
        }
    },
    computed : {
        stateReload () {
            return this.$store.state.stateReload
        },
        listData () {
            return this.$store.state.list
        },
        commonObj () {
            return this.$store.state.commonObj
        }
    },
    methods:{
        searchData () {
            this.loadSummary()  
            this.loadData()
        },
        async loadSummary () {
            this.loader = true
            const response = await config.getData('/area/summary', this.search)
            this.loader = false
            this.summary = response.data          
        },
        async loadData(){     
            this.loader = true 
            const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })          
            const response = await config.getData('/area', params)
            this.loader = false
            if (response.status == 200){
                this.$store.dispatch('setList', response.data.data)
                this.paginationData(response.data)
            } else {
                this.$store.dispatch('setList', [])
            } 
        },
        paginationData (data) {
            this.pagination.currentPage = data.current_page
            this.pagination.totalRows = data.total
            this.pagination.perPage = data.per_page
            this.pagination.slOffset = this.pagination.perPage * this.pagination.currentPage - this.pagination.perPage + 1
        },
        showCreateModal(){
            this.createModal = true;
        },
        async createArea(){
            this.loader = true
            this.$store.dispatch('stateReload', true)
            const response = await config.postData('/area/store', this.form)
            this.loader = false
            if (response.status == 201) {
                this.$store.dispatch('commonObjLoad', true)
                this.createModal = false;
                this.form.coverage_area_id = this.form.division_id  = this.form.district_id = this.form.thana_id = this.form.name = ''          
                this.$toast.success({
                    title: 'Success',
                    message: 'Area created successfully',
                    color: '#218838'
                }) 
            } else {
                this.$refs.form.setErrors(response.errors)
                this.$toast.error({
                    title: 'Error',
                    message: 'Sorry, something went wrong',
                    color: '#dc3545'
                })   
            }
            this.$store.dispatch('stateReload', false)
        },
        showBulkModal(){
            this.bulkModal = true;
        },
        onFileChange(e){
            this.bulk_upload.excel_file = e.target.files[0];
        },
        async bulkUploadSave () {
            this.loader = true
            this.$store.dispatch('stateReload', true)
            let formData = new FormData();
            formData.append('excel_file', this.bulk_upload.excel_file);
            const response = await config.postData('/area/bulk-upload', formData)
            this.loader = false  
            if (response.status == 201) {
                this.bulkModal = false
                this.bulk_upload.excel_file = ''
                this.$toast.success({
                    title: 'Success',
                    message: 'Data uploaded successfully',
                    color: '#218838'
                }) 
            } else {
                this.$toast.error({
                    title: 'Error',
                    message: 'Sorry, something went wrong',
                    color: '#dc3545'
                })
            }                  
            this.$store.dispatch('stateReload', false)
        },
        cancelModal(){
            this.createModal = false;
            this.editModal = false;
            this.deleteModal = false;
            this.bulkModal = false;
            this.form.coverage_area_id = this.form.division_id  = this.form.district_id = this.form.thana_id = this.form.name = ''
        },
        editArea(area){
            this.editModal = true;
            this.form_edit = area;
        },
        async updateArea(){
            this.loader = true
            this.$store.dispatch('stateReload', true)
            const response = await config.postData('/area/update', this.form_edit)
            this.loader = false
            if (response.status == 201) {
                this.$store.dispatch('commonObjLoad', true)
                this.editModal = false;            
                this.$toast.success({
                    title: 'Success',
                    message: 'Area updated successfully',
                    color: '#218838'
                })  
            } else {
                this.$refs.editForm.setErrors(response.errors)
                this.$toast.error({
                    title: 'Error',
                    message: 'Sorry, something went wrong',                    
                    color: '#dc3545'
                })  
            }
            this.$store.dispatch('stateReload', false)
        },
        deleteArea(key, area_id){
            this.area_id = area_id;
            this.key = key;
            this.deleteModal = true;
        },
        async destroyArea(){
            this.loader = true
            this.$store.dispatch('stateReload', true)
            await config.deleteData('/area/destroy/'+this.area_id)
            this.loader = false           
            this.deleteModal = false
            this.$toast.success({
                title: 'Success',
                message: 'Area deleted successfully',
                color: '#218838'
            }) 
            this.$store.dispatch('stateReload', false)
        },
        getDistrictList (divisionId) {
            return this.commonObj.districtList.filter(dist => dist.division_id == divisionId)
        },
        getThanaList (districtId) {
            return this.commonObj.thanaList.filter(thana => thana.district_id == districtId)
        }
    }
}
</script>